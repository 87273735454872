import { legend } from "@/core/utils/legend-state/core";
import { env } from "@/env";
import { SquareTerminal, Bot, RotateCw, Frame, PieChart, LifeBuoy } from "lucide-react";

interface NavItem {
  title: string;
  url: string;
  icon?: React.ComponentType;
  isActive?: boolean;
  disabled?: boolean;
  isExternal?: boolean;
  items?: {
    title: string;
    url: string;
    disabled?: boolean;
    isExternal?: boolean;
  }[];
}

interface IState {
  main: Record<string, NavItem>;
  myReports: Record<string, NavItem>;
  getMainAsArray: () => NavItem[];
  getMyReportsAsArray: () => NavItem[];
}

export const local$ = legend.state.observable<IState>({
  getMainAsArray() {
    const result = local$.main.get() as Record<string, NavItem>;
    return Object.values(result);
  },
  getMyReportsAsArray() {
    const result = local$.myReports.get() as Record<string, NavItem>;
    return Object.values(result);
  },
  main: {
    "company-search": {
      title: "My Companies",
      url: "/",
      icon: Bot,
    },
    support: {
      title: "Help Center",
      url: "#",
      icon: LifeBuoy,
      items: [
        {
          title: "Methodology & Guides",
          url: "#",
          disabled: true,
        },
        {
          title: "FAQs",
          url: env.VITE_FAQ,
          isExternal: true,
        },
      ],
    },
    changelog: {
      title: "Changelog",
      url: "#",
      icon: RotateCw,
      disabled: true,
    },
  },
  myReports: {
    "create-new-report": {
      title: "Create New Report",
      url: "#",
      icon: Frame,
      disabled: true,
    },
    "culture-essentials": {
      title: "Culture Essentials",
      url: "#",
      icon: PieChart,
      disabled: true,
    },
  },
});

export const state$ = {
  local$,
};
