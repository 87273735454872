import { Separator } from "@/core/shadcn/ui/separator";
import { SidebarTrigger } from "@/core/shadcn/ui/sidebar";
import {
  Breadcrumb,
  BreadcrumbSeparator,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
} from "@/core/shadcn/ui/breadcrumb";
import { legend } from "@/core/utils/legend-state/core";
import { LoadingSpinner } from "@/core/components/loading-spinner";
import { Suspense, useEffect, useMemo } from "react";
import { Progress } from "@/core/shadcn/ui/progress";
import { report_builder } from "./state";
import { rc_registry } from "./rcs/rc-registry";
import { Card, CardContent, CardHeader } from "@/core/shadcn/ui/card";
import { Skeleton } from "@/core/shadcn/ui/skeleton";
import { Button } from "@/core/shadcn/ui/button";
import { FolderDown } from "lucide-react";
import { testIds } from "./test-ids";

export const ReportBuilder = legend.react.observer(() => {
  function handleClickExport() {
    report_builder.actions.export();
  }

  return (
    <div className="bg-accent/50 dark:bg-background" data-testid="main-content">
      <header
        className="flex items-center gap-2 px-4 h-[64px] border-0 border-input w-full border-b-1"
        data-testid="header"
      >
        <SidebarTrigger className="-ml-1" />
        <Separator orientation="vertical" className="mr-2 h-4" />
        <legend.react.Memo>
          {() => (
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbSeparator className="hidden md:block" />
                <BreadcrumbItem>
                  <BreadcrumbPage>Report Builder</BreadcrumbPage>
                </BreadcrumbItem>
                <BreadcrumbSeparator className="hidden md:block" />

                <BreadcrumbItem>
                  <BreadcrumbPage>
                    {report_builder.remote.report.pending.get() ? (
                      <LoadingSpinner className="size-3" />
                    ) : (
                      report_builder.remote.report.data.title.get()
                    )}
                  </BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          )}
        </legend.react.Memo>
      </header>
      <legend.react.Memo>
        {() => {
          const progress = report_builder.remote.report.progress.get();
          return (
            <Progress
              value={progress}
              className={`w-full h-[6px] border-0 border-border border-b-0 shadow-none rounded-none ${
                progress === 100 ? "opacity-0 transition-opacity duration-600" : ""
              }`}
            />
          );
        }}
      </legend.react.Memo>
      <div className="flex flex-col p-4 gap-5">
        <Suspense fallback={<Fallback />}>
          <div className="flex items-center justify-between">
            <span className="font-semibold text-[24px] leading-[32px]">
              {report_builder.remote.report.data.title.get()}
            </span>
            <legend.react.Memo>
              {() => {
                const isPending = report_builder.local.export.pending.get();
                return (
                  <Button
                    variant="outline"
                    className="shadow-shadow-xs flex gap-3"
                    onClick={handleClickExport}
                    disabled={isPending}
                  >
                    Export
                    {isPending ? <LoadingSpinner className="size-3" /> : <FolderDown className="size-4" />}
                  </Button>
                );
              }}
            </legend.react.Memo>
          </div>
          <ResolveRCComponentStack />
        </Suspense>
      </div>
    </div>
  );
});

// Create a wrapper component to handle both loading states
const ResolveRCComponentStack = legend.react.observer(() => {
  // This will throw if pending, triggering Suspense
  if (report_builder.remote.report.pending.get()) {
    throw new Promise((resolve) => {
      const unsubscribe = report_builder.remote.report.pending.onChange((isPending) => {
        if (!isPending.value) {
          unsubscribe();
          resolve(true);
        }
      });
    });
  }

  const rcs = report_builder.remote.report.data.rcs.get();
  const deps = JSON.stringify(rcs.map((remoteRC) => remoteRC.instance_id));

  const comps = useMemo(
    () =>
      rcs.map((remoteRC) => {
        const Component = rc_registry.resolve_RC_component(remoteRC.rc_id, { throwError: false });
        if (!Component) return null;
        return <Component key={remoteRC.instance_id} instance_id={remoteRC.instance_id} />;
      }),
    [deps],
  );

  const progress = report_builder.remote.report.progress.get();
  useEffect(() => {
    if (comps.length === 0 || comps === null || progress === 100) return;
    report_builder.remote.report.progress.set(100);
  }, [comps, progress]);

  return (
    <div className="w-full gap-4 flex flex-col" {...testIds.rcStack}>
      {comps}
    </div>
  );
});

const Fallback = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="h-8 w-1/3 bg-muted animate-pulse rounded" />
        <div className="h-4 w-1/4 bg-muted animate-pulse rounded" />
      </div>
      <DataComponentSkeleton />
      <DataComponentSkeleton />
      <DataComponentSkeleton />
      <DataComponentSkeleton />
    </div>
  );
};

const DataComponentSkeleton = () => {
  return (
    <Card className="border-border">
      <CardHeader className="flex items-center gap-2 space-y-0 border-b border-border py-2 sm:flex-row px-2">
        <div className="flex-1 gap-2 text-center sm:text-left flex items-center">
          <Skeleton className="size-[24px] rounded-lg" />
          <Skeleton className="h-[16px] w-[137px] rounded-lg" />
        </div>

        <Skeleton className="h-[24px] max-w-[100px] w-full rounded-lg" />
        <Skeleton className="h-[24px] max-w-[100px] w-full rounded-lg" />
      </CardHeader>

      <CardHeader className="flex flex-col items-stretch space-y-0 border-b border-border p-0 sm:flex-row">
        <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
          <Skeleton className="h-[24px] max-w-[180px] w-full rounded-lg" />
          <Skeleton className="h-[20px] max-w-[500px] w-full rounded-lg" />
        </div>
      </CardHeader>
      <CardContent className="pt-5">
        <Skeleton className="h-[200px] w-full rounded-lg" />
      </CardContent>
    </Card>
  );
};
