import * as React from "react";
import { ChevronsUpDown } from "lucide-react";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarRail,
} from "@/core/shadcn/ui/sidebar";
import { DeltabaseLogo } from "@/assets/logos";
import { FooterMenu, MainNavItems, MyReports, FeaturedCard } from "./components";

interface IProps {
  children: React.ReactNode;
}

export const MainMenuUI = ({ children }: IProps) => {
  return (
    <SidebarProvider>
      <div className="dark">
        <Sidebar collapsible="icon">
          <SidebarHeader>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  size="lg"
                  className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                >
                  <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                    <DeltabaseLogo className="size-4" />
                  </div>
                  <div className="grid flex-1 text-left text-sm leading-tight">
                    <span className="truncate font-semibold">Deltabase</span>
                    <span className="truncate text-xs">Platform</span>
                  </div>
                  <ChevronsUpDown className="ml-auto" />
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarHeader>
          <SidebarContent>
            <MainNavItems />
            {/* <MyReports /> */}
            <SidebarGroup>
              <FeaturedCard />
            </SidebarGroup>
          </SidebarContent>
          <SidebarFooter>
            <FooterMenu />
          </SidebarFooter>
          <SidebarRail />
        </Sidebar>
      </div>
      <SidebarInset>{children}</SidebarInset>
    </SidebarProvider>
  );
};
