// Helpers

import { merge } from "lodash";
import { local_schema, ILocalState$, IRemoteState$, ICreateStateProps, IObservers, IActions } from "./types";
import { api, IAPI, IContentExtract, IMeasure } from "@/core/shared/api";
import { transforms } from "@/core/shared/transforms";
import { z } from "zod";
import { legend } from "@/core/utils/legend-state/core";
import { rc } from "../../rc";
import { auth } from "@/core/state/auth";
import { metadata } from "@/core/state/metadata";
import { report_builder } from "@/features/report-builder/state";
import { ITransformedContentExtract } from "../../../extracts/instance/state/types";

// Config
const config: z.infer<typeof local_schema>["config"] = {
  company: {
    measure_id_to_measure_name_map: {
      NSS: "NSS_PER_COMPANY_PER_TOPIC",
      PREVALENCE: "PREVALENCE_PER_COMPANY_PER_TOPIC",
      RATING: "RATING_PER_COMPANY_PER_TOPIC",
      REVIEW_COUNT: "COUNT_PER_COMPANY_PER_TOPIC",
    },
    filters: {
      hide: false,
    },
  },
  measure: {
    hide: false,
    default: "NSS",
    measures: [
      {
        id: "NSS",
        label: "Employee Net Sentiment Score (NSS)",
        unit: "%",
        acronym: "NSS",
        showSign: true,
        domain: [-100, 100],
        filter: {
          checked: true,
          disabled: false,
        },
      },
      {
        id: "PREVALENCE",
        label: "Topic Frequency ",
        unit: "%",
        acronym: "PREV",
        domain: [0, 100],
        filter: {
          checked: true,
          disabled: false,
        },
      },
      {
        id: "RATING",
        label: "Employee Rating",
        domain: [0, 5],
        filter: {
          checked: true,
          disabled: false,
        },
      },
      {
        id: "REVIEW_COUNT",
        label: "Employee Review Count",
        domain: undefined,
        compactYAxisValues: true,
        filter: {
          checked: true,
          disabled: false,
        },
      },
    ],
  },
  benchmark: {
    hide: false,
    benchmarks: [
      {
        id: "PEER_SET_AVG",
        label: "Peer set average",
        measure_id_to_measure_name_map: {
          NSS: "NSS_PER_TOPIC",
          PREVALENCE: "PREVALENCE_PER_TOPIC",
          RATING: "RATING_PER_TOPIC",
          REVIEW_COUNT: "COUNT_PER_TOPIC",
        },
        filter: {
          checked: true,
          disabled: false,
        },
      },
    ],
  },
};

export function createState(
  instance_id: string,
  props: ICreateStateProps,
): { local: ILocalState$; remote: IRemoteState$ } {
  const local = helpers.validateAndMergeState(
    {
      label: "Employee Voice: Company Personality",
      ui_merged: false,
      config,
      views: {
        currentView: "analysis",
        analysis: {
          chart_config: {
            entities: {},
            polar_axis: {
              data_key: "topic",
              label: "Topic",
            },
          },
          selectors: {
            measure: {
              selectedMeasureId: "NSS",
            },
          },
          filters: {
            company: legend.features.filter.createObservableState(),
            topic: legend.features.filter.createObservableState(),
            timeframe: legend.features.filter.createObservableState(),
            benchmark: legend.features.filter.createObservableState(),
            applied_filters: {
              company_id: [],
              topic_name: [],
              timeframe: [],
              benchmarks: [],
            },
          },
        },
      },
    },
    props.local,
  );

  const remote: IRemoteState$ = {
    measures: {
      pending: false,
      error: false,
      data: legend.sync.synced({
        initial: [],
        get: async () => {
          const instance = rc.registry.actions.getInstance(instance_id);
          try {
            instance.remote.measures.pending.set(true);
            const selected_measure_id = instance.local.views.analysis.selectors.measure.selectedMeasureId.get();

            const company_measure_name =
              instance.local.config.company.measure_id_to_measure_name_map.get()[selected_measure_id];

            // convert benchmarks to measure names
            const benchmark_measure_names: string[] = [];
            instance.local.views.analysis.filters.applied_filters.benchmarks.get()?.forEach((benchmark_id) => {
              const benchmark = instance.local.config.benchmark.benchmarks.get().find((b) => b.id === benchmark_id);
              if (!benchmark) return;
              benchmark_measure_names.push(benchmark.measure_id_to_measure_name_map[selected_measure_id]);
            });

            const benchmark_config = instance.local.config.benchmark.benchmarks.get();
            const company_config = instance.local.config.company.get();

            const params: Parameters<IAPI["deltabase_data_api"]["measures"]["get"]>["0"] = {
              company_id: instance.local.views.analysis.filters.applied_filters.company_id.get(),
              topic_name: instance.local.views.analysis.filters.applied_filters.topic_name.get(),
              year: instance.local.views.analysis.filters.applied_filters.timeframe.get(),
              measure_name: Array.from(new Set([company_measure_name, ...benchmark_measure_names])),
              sort_field: "year",
              sort_direction: "ASC",
            };

            // Guard against no company selected - Data API will return an error 400 otherwise
            if (params.company_id?.length === 0) {
              return [];
            }

            const response = await api.deltabase_data_api.measures.get(params);

            // group by topic
            const grouped_data: Record<string, IMeasure[]> = {};
            response.data.forEach((measure) => {
              const topic = measure.topic_name;
              if (!grouped_data[topic]) {
                grouped_data[topic] = [];
              }
              grouped_data[topic].push(measure);
            });

            type ChartRecord = Record<string, number | string | null>;
            const chart_data: ChartRecord[] = [];

            Object.entries(grouped_data).forEach(([topic, measures]) => {
              const entry: ChartRecord = { topic: topic };
              measures.forEach((measure) => {
                const score = helpers.transformMeasureScore(measure, selected_measure_id);

                // Identify which entity the measure belongs to
                const matchToBenchmark = benchmark_config.find(
                  (benchmark) => benchmark.measure_id_to_measure_name_map[selected_measure_id] === measure.measure_name,
                );
                const matchToCompany =
                  company_config.measure_id_to_measure_name_map[selected_measure_id] === measure.measure_name;

                // If the measure belongs to a benchmark, add it to the chart data
                if (matchToBenchmark) {
                  entry[matchToBenchmark.id] = score;
                  return;
                }

                // If the measure belongs to the company, add it to the chart data
                if (matchToCompany) {
                  entry[measure.company_id] = score;
                  return;
                }

                // If the measure doesn't belong to a benchmark or the company, throw an error
                throw new Error(`Measure not associated with a benchmark or company entity: ${measure.measure_name}`);
              });
              chart_data.push(entry);
            });

            legend.state.batch(() => {
              instance.remote.measures.pending.set(false);
              instance.remote.measures.error.set(false);
            });
            return chart_data;
          } catch (error) {
            debugger;
            legend.state.batch(() => {
              instance.remote.measures.pending.set(false);
              instance.remote.measures.error.set(true);
            });
          }
        },
        waitFor: () => {
          const token = auth.state$.token.get();
          return !!token;
        },
      }),
    },
    evidence: {
      pending: false,
      error: false,
      data: legend.sync.synced({
        initial: {},
        get: async () => {
          const instance = rc.registry.actions.getInstance(instance_id);
          try {
            instance.remote.evidence.pending.set(true);
            const topic_metadata = metadata.remote$.data.cached_transformed["topic"].get();
            const peerset = report_builder.remote.peerset.data.get();

            const params: Parameters<IAPI["deltabase_data_api"]["content_extracts"]["get"]>["0"] = {
              company_id: instance.local.views.analysis.filters.applied_filters.company_id.get(),
              topic_name: instance.local.views.analysis.filters.applied_filters.topic_name.get(),
              year: instance.local.views.analysis.filters.applied_filters.timeframe.get(),
              sentiment_type: [],
            };

            if (params.company_id?.length === 0) {
              return {};
            }
            const response = await api.deltabase_data_api.content_extracts.get(params);

            const evidenceData: Record<string, Record<string, Record<string, Array<ITransformedContentExtract>>>> = {};

            response.data.forEach((extract) => {
              const { topic_name, company_id, text, sentiment_type } = extract;
              const topic = topic_metadata.find((t) => t.meta_data_value === topic_name);

              const group = topic?.meta_data_parent_value ?? topic_name;
              if (!group) return;

              // Initialize nested structure
              if (!evidenceData[group]) evidenceData[group] = {};
              if (!evidenceData[group][topic_name]) evidenceData[group][topic_name] = {};
              if (!evidenceData[group][topic_name][company_id]) evidenceData[group][topic_name][company_id] = [];

              const company = peerset[company_id];

              const cleanedExtract: ITransformedContentExtract = {
                ...extract,
                text: text.replace(/['"]/g, ""),
                company: company ?? null,
              };

              const currentExtracts = evidenceData[group][topic_name][company_id];
              const hasBothSentiments =
                (params?.sentiment_type?.includes("Positive") && params?.sentiment_type?.includes("Negative")) ||
                params?.sentiment_type?.length === 0;

              if (hasBothSentiments) {
                // If we want both sentiments, ensure we get one positive and one negative
                if (sentiment_type === "Positive" && !currentExtracts.some((e) => e.sentiment_type === "Positive")) {
                  currentExtracts.unshift(cleanedExtract); // Add positive first
                } else if (
                  sentiment_type === "Negative" &&
                  !currentExtracts.some((e) => e.sentiment_type === "Negative")
                ) {
                  currentExtracts.push(cleanedExtract); // Add negative second
                }
              } else if (currentExtracts.length < 2) {
                // If not filtering by both sentiments, just add up to 2 extracts
                currentExtracts.push(cleanedExtract);
              }
            });

            // Sort topics alphabetically within each group
            for (const group in evidenceData) {
              const sortedTopics = Object.keys(evidenceData[group]).sort();
              const sortedGroupData: Record<string, Record<string, Array<ITransformedContentExtract>>> = {};

              sortedTopics.forEach((topicName) => {
                sortedGroupData[topicName] = evidenceData[group][topicName];
              });

              evidenceData[group] = sortedGroupData;
            }

            legend.state.batch(() => {
              instance.remote.evidence.pending.set(false);
              instance.remote.evidence.error.set(false);
            });
            return evidenceData;
          } catch (error) {
            debugger;
            legend.state.batch(() => {
              instance.remote.evidence.error.set(true);
            });
          } finally {
            instance.remote.evidence.pending.set(false);
          }
        },
        waitFor: () => {
          const token = auth.state$.token.get();
          return !!token;
        },
      }),
    },
  };

  return { local, remote };
}

export const actions: IActions = {
  views: {
    analysis: {
      selectors: {
        measure: {
          set: (instance_id: string, measure_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const measure = instance.local.config.measure.measures.get().find((m) => m.id === measure_id);
            if (!measure) return;
            legend.state.batch(() => {
              instance.local.views.analysis.selectors.measure.selectedMeasureId.set(measure_id);
              instance.local.views.analysis.chart_config.polar_axis.assign({
                label: `${measure.label}  ${measure.unit ? `(${measure.unit})` : ""}`,
                domain: measure.domain,
                compactValues: measure.compactYAxisValues,
                unit: measure.unit,
              });
            });
          },
        },
      },
      filters: {
        benchmark: {
          applyAll: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const filters = instance.local.views.analysis.filters.benchmark.data.get();
            const selected_measure = instance.local.views.analysis.selectors.measure.selectedMeasureId.get();

            const benchmarks: string[] = [];
            Object.values(filters).forEach((filter) => {
              if (filter.checked) {
                const benchmark = instance.local.config.benchmark.benchmarks.get().find((b) => b.id === filter.id);
                if (benchmark?.id === "PEER_SET_AVG") {
                  benchmarks.push(benchmark.id);
                }
              }
            });
            instance.local.views.analysis.filters.applied_filters.benchmarks.set(benchmarks);
          },
          toggle: (instance_id: string, filter_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggle({
              state: instance.local.views.analysis.filters.benchmark,
              params: { id: filter_id },
            });
          },
          toggleGroup: (instance_id: string, filter_group: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggleGroup({
              state: instance.local.views.analysis.filters.benchmark,
              params: { group: filter_group },
            });
          },
          getFiltersByGroup: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const out = legend.features.filter.getFiltersByGroup({
              state: instance.local.views.analysis.filters.benchmark,
            });
            return out;
          },
        },
        company: {
          applyAll: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const applied: string[] = [];
            const filters = instance.local.views.analysis.filters.company.data.get();
            Object.values(filters).forEach((filter) => {
              if (filter.checked) {
                applied.push(filter.id);
              }
            });
            instance.local.views.analysis.filters.applied_filters.company_id.set(applied);
          },
          toggle: (instance_id: string, filter_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggle({
              state: instance.local.views.analysis.filters.company,
              params: { id: filter_id },
            });
          },
          toggleGroup: (instance_id: string, filter_group: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggleGroup({
              state: instance.local.views.analysis.filters.company,
              params: { group: filter_group },
            });
          },
          getFiltersByGroup: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const out = legend.features.filter.getFiltersByGroup({
              state: instance.local.views.analysis.filters.company,
            });
            return out;
          },
        },
        topic: {
          applyAll: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const applied: string[] = [];
            const filters = instance.local.views.analysis.filters.topic.data.get();
            Object.values(filters).forEach((filter) => {
              if (filter.checked) {
                applied.push(filter.id);
              }
            });
            instance.local.views.analysis.filters.applied_filters.topic_name.set(applied);
          },
          toggle: (instance_id: string, filter_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggle({
              state: instance.local.views.analysis.filters.topic,
              params: { id: filter_id },
            });
          },
          toggleGroup: (instance_id: string, filter_group: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggleGroup({
              state: instance.local.views.analysis.filters.topic,
              params: { group: filter_group },
            });
          },
          getFiltersByGroup: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const out = legend.features.filter.getFiltersByGroup({
              state: instance.local.views.analysis.filters.topic,
            });
            return out;
          },
        },
        timeframe: {
          applyAll: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const applied: string[] = [];
            const filters = instance.local.views.analysis.filters.timeframe.data.get();
            Object.values(filters).forEach((filter) => {
              if (filter.checked) {
                applied.push(filter.id);
              }
            });
            instance.local.views.analysis.filters.applied_filters.timeframe.set(applied);
          },
          toggle: (instance_id: string, filter_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggle({
              state: instance.local.views.analysis.filters.timeframe,
              params: { id: filter_id },
            });
          },
          toggleGroup: (instance_id: string, filter_group: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggleGroup({
              state: instance.local.views.analysis.filters.timeframe,
              params: { group: filter_group },
            });
          },
          toggleByRange: (instance_id, range) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const filters_obs = instance.local.views.analysis.filters.timeframe.data;
            const filters_arr = Object.values(filters_obs.get());
            const [startYear, endYear] = range.split("-");

            // For single year selection
            if (!endYear) {
              const targetFilter = filters_arr.find((filter) => filter.id === startYear);
              const newCheckedState = !targetFilter?.checked;

              filters_arr.forEach((filter) => {
                const checked = filter.id === startYear ? newCheckedState : false;
                filters_obs[filter.id].checked.set(checked);
              });
              return;
            }

            // For year range selection
            // First check if all filters in range are already checked
            const filtersInRange = filters_arr.filter((filter) => {
              const year = parseInt(filter.id);
              return year >= parseInt(startYear) && year <= parseInt(endYear);
            });
            const allChecked = filtersInRange.every((filter) => filter.checked);

            // Toggle based on current state
            filters_arr.forEach((filter) => {
              const year = parseInt(filter.id);
              const inRange = year >= parseInt(startYear) && year <= parseInt(endYear);
              // If all are checked, uncheck them; if not all checked, check them
              const checked = inRange ? !allChecked : false;
              filters_obs[filter.id].checked.set(checked);
            });
          },
          getFiltersByGroup: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const out = legend.features.filter.getFiltersByGroup({
              state: instance.local.views.analysis.filters.timeframe,
            });
            return out;
          },
        },
      },
    },
  },
};

export const observers: IObservers = {
  view: {
    analysis: {
      chart_config: {
        sync_to_state: (instance_id: string) => {
          if (!instance_id) return;
          legend.state.observe(() => {
            const instance = rc.registry.actions.getInstance(instance_id);

            const peerset = report_builder.remote.peerset.data.get();

            // sync chart config
            const applied_company_id = instance.local.views.analysis.filters.applied_filters.company_id.get() ?? [];
            const applied_benchmarks = instance.local.views.analysis.filters.applied_filters.benchmarks.get() ?? [];

            const config: z.infer<typeof local_schema>["views"]["analysis"]["chart_config"]["entities"] = {};

            // add benchmark entities to chart config
            applied_benchmarks.forEach((benchmark_id, idx) => {
              const benchmark = instance.local.config.benchmark.benchmarks.get().find((b) => b.id === benchmark_id);
              if (!benchmark) return;

              if (benchmark.id === "PEER_SET_AVG") {
                config[benchmark.id] = {
                  id: benchmark.id,
                  label: benchmark.label,
                  color: `hsl(var(--chart-deltabase-peer_set_avg))`,
                };
              }
            });

            // add company entities to chart config
            applied_company_id.forEach((company_id, idx) => {
              const company = peerset[company_id];
              if (!company) return;
              config[company_id] = {
                id: company_id,
                label: company?.company_name ?? "",
                color: `hsl(var(--chart-deltabase-company-${idx + 1}))`,
              };
            });

            instance.local.views.analysis.chart_config.entities.set(config);
          });
        },
      },
      filters: {
        benchmark: {
          init: (instance_id: string) => {
            if (!instance_id) return;
            legend.state.observe<{ initialised: boolean }>((e) => {
              if (e.previous?.initialised) return;
              const instance = rc.registry.actions.getInstance(instance_id);
              const benchmarks = instance.local.config.benchmark.benchmarks.get();

              if (!benchmarks || benchmarks.length === 0) return;

              // populate filter state
              legend.state.batch(() => {
                legend.features.filter.batchAddFilter({
                  state: instance.local.views.analysis.filters.benchmark,
                  params: {
                    data: benchmarks.map((benchmark) => {
                      const out = {
                        id: benchmark.id,
                        label: benchmark.label,
                        checked: benchmark.filter.checked,
                        disabled: benchmark.filter.disabled,
                        group: "",
                      };
                      return out;
                    }),
                  },
                });
                actions.views.analysis.filters.benchmark.applyAll(instance_id);
              });

              return { initialised: true };
            });
          },
        },
        company: {
          init: (instance_id: string) => {
            if (!instance_id) return;
            legend.state.observe<{ peerset_key: string }>((e) => {
              const instance = rc.registry.actions.getInstance(instance_id);
              const peerset = report_builder.remote.peerset.data.get();
              const current_peerset_key = JSON.stringify(Object.keys(peerset));

              if (!peerset || current_peerset_key.length === 0 || current_peerset_key === e.previous?.peerset_key) {
                return;
              }

              const companies = Object.values(peerset);

              // populate filter state
              legend.state.batch(() => {
                legend.features.filter.batchAddFilter({
                  state: instance.local.views.analysis.filters.company,
                  params: {
                    data: companies.map((company, idx) => ({
                      id: company.company_id,
                      label: company.company_name,
                      checked: true,
                      disabled: false,
                      group: "",
                    })),
                  },
                });
                actions.views.analysis.filters.company.applyAll(instance_id);
              });

              const peerset_key = JSON.stringify(Object.keys(peerset));

              return { peerset_key };
            });
          },
        },
        topic: {
          init: (instance_id: string) => {
            if (!instance_id) return;
            legend.state.observe<{ initialised: boolean }>((e) => {
              if (e.previous?.initialised) return;
              const instance = rc.registry.actions.getInstance(instance_id);
              const topic_metadata = metadata.remote$.data.get().cached_transformed["topic"];

              if (!topic_metadata || topic_metadata.length === 0) return;

              // populate filter state
              legend.state.batch(() => {
                legend.features.filter.batchAddFilter({
                  state: instance.local.views.analysis.filters.topic,
                  params: {
                    data: topic_metadata.map((topic) => ({
                      id: topic.meta_data_value,
                      label: topic.meta_data_value.toLowerCase(),
                      checked: true,
                      disabled: false,
                      group: topic.meta_data_parent_value ?? "",
                    })),
                  },
                });
                actions.views.analysis.filters.topic.applyAll(instance_id);
              });

              return { initialised: true };
            });
          },
        },
        timeframe: {
          init: (instance_id: string) => {
            if (!instance_id) return;
            legend.state.observe<{ initialised: boolean }>((e) => {
              if (e.previous?.initialised) return;
              const instance = rc.registry.actions.getInstance(instance_id);
              const timeframe_metadata = metadata.remote$.data.get().cached_transformed["year"];
              const company_search_timeframe = report_builder.local.search.time_periods.get();

              if (!timeframe_metadata || timeframe_metadata.length === 0 || company_search_timeframe.length === 0) {
                return { initialised: false };
              }

              // populate filter state
              legend.state.batch(() => {
                legend.features.filter.batchAddFilter({
                  state: instance.local.views.analysis.filters.timeframe,
                  params: {
                    data: timeframe_metadata.map((timeframe) => {
                      const out = {
                        id: timeframe.meta_data_value,
                        label: timeframe.meta_data_value.toLowerCase(),
                        checked: company_search_timeframe?.includes(timeframe.meta_data_value) ?? false,
                        disabled: false,
                        group: "",
                      };
                      return out;
                    }),
                  },
                });
                actions.views.analysis.filters.timeframe.applyAll(instance_id);
              });

              return { initialised: true };
            });
          },
        },
      },
    },
  },
};

const helpers = {
  validateAndMergeState: (state: ILocalState$, new_state?: object) => {
    if (new_state !== undefined) {
      const isValid = local_schema.safeParse(new_state);
      if (!isValid.success) {
        throw new Error(
          `Invalid props provided to createState$. Validation errors: ${JSON.stringify(isValid.error.format(), null, 2)}`,
        );
      }
      state = merge(state, new_state);
      return state;
    }
    return state;
  },

  transformMeasureScore: (measure: IMeasure, measure_type: string) => {
    if (measure_type === "NSS") {
      const score = transforms.nss.toPercentage(measure.measure_value);
      return score;
    } else if (measure_type === "PREVALENCE") {
      const score = transforms.prevalence.toPercentage(measure.measure_value);
      return score;
    } else if (measure_type === "RATING") {
      const score = transforms.rating.toNumber(measure.measure_value);
      return score;
    } else if (measure_type === "REVIEW_COUNT") {
      const score = transforms.count.toNumber(measure.measure_value);
      return score;
    }

    return null;
  },
};
