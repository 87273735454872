import { lazy } from "react";
import { IRC, IRC_Exportable, IRC_Exportable_PPTX_Chart, IRC_Exportable_PPTX_Table } from "../../../types";
import { legend } from "@/core/utils/legend-state/core";
import { Observable } from "@legendapp/state";
import { createContextProvider } from "@/core/utils/context-api";
import { state, IState } from "./instance/state";
import { report_builder } from "@/features/report-builder/state";
import { hslToHex } from "@/core/utils/tailwind-toolkit/utils";
import { sanitizeForCSV } from "../../../helpers";
import { capitalize } from "lodash";
import pptxgen from "pptxgenjs";

const RC_ID = "employee_voice_company_personality";

export const rc: IRC<IState.ILocalState$, IState.IRemoteState$, { instance_id: string }> = {
  rc_id: RC_ID,
  instance: {
    component: lazy(() =>
      import("./instance/ui/index").then((module) => ({
        default: module.RC_Component,
      })),
    ),
  },
  registry: {
    state$: legend.state.observable<{
      local_instances: Record<string, Observable<IState.ILocalState$>>;
      remote_instances: Record<string, Observable<IState.IRemoteState$>>;
    }>({
      local_instances: {},
      remote_instances: {},
    }),
    context: createContextProvider<{
      instance_id: string;
    }>(),
    actions: {
      createInstance: (instance_id, options) => {
        const { local, remote } = state.createState(instance_id, { local: options.local });

        legend.state.batch(() => {
          rc.registry.state$.local_instances[instance_id].set(local);
          rc.registry.state$.remote_instances[instance_id].set(remote);

          report_builder.local.active_rc_registries.assign({
            [RC_ID]: RC_ID,
          });
        });
      },
      deleteInstance: (id) => {
        legend.state.batch(() => {
          rc.registry.state$.remote_instances[id].delete();
          rc.registry.state$.local_instances[id].delete();
          if (rc.registry.actions.isEmpty()) {
            report_builder.local.active_rc_registries[RC_ID].delete();
          }
        });
      },
      cleanUp: () => {
        legend.state.batch(() => {
          rc.registry.state$.remote_instances.set({});
          rc.registry.state$.local_instances.set({});
          report_builder.local.active_rc_registries[RC_ID].delete();
        });
        // TODO: clean up local storage data and remote data if cache is effected
      },
      getInstance: (id) => {
        const remote = rc.registry.state$.remote_instances[id];
        const local = rc.registry.state$.local_instances[id];
        if (!remote) {
          throw new Error(`Remote instance with id ${id} not found`);
        }
        if (!local) {
          throw new Error(`Local instance with id ${id} not found`);
        }
        return { remote, local };
      },
      isEmpty: () => {
        return (
          Object.keys(rc.registry.state$.remote_instances.get()).length === 0 &&
          Object.keys(rc.registry.state$.local_instances.get()).length === 0
        );
      },
      exportInstance: async (id) => {
        const instance = rc.registry.actions.getInstance(id);

        const exportable: IRC_Exportable = {
          folder_path: "Culture/Employee-Voice/Company-Personality",
          master: {
            pptx: {
              powerpoint_name: "What are the strengths and weaknesses of a Company's Culture?",
              merge_views_to_master_pptx: true,
            },
          },
          views: [
            {
              view_name: "Main Analysis",
              formats: {
                pptx: {
                  powerpoint_name: "What are the strengths and weaknesses of a Company's Culture?",
                  slide_data: [
                    {
                      title: "Analysis - What are the strengths and weaknesses of a Company's Culture?",
                      chart: await helpers.views.analysis.visualisation.pptx(instance),
                    },
                    {
                      title: "Evidence - What are the strengths and weaknesses of a Company's Culture?",
                      table: await helpers.views.analysis.evidence.pptx(instance),
                    },
                  ],
                },
                csv: {
                  data: [
                    {
                      name: "Analysis - What are the strengths and weaknesses of a Company's Culture?",
                      content: await helpers.views.analysis.visualisation.csv(instance),
                    },
                    {
                      name: "Evidence - What are the strengths and weaknesses of a Company's Culture?",
                      content: await helpers.views.analysis.evidence.csv(instance),
                    },
                  ],
                },
              },
            },
          ],
        };

        return exportable;
      },
    },
  },
};
// #endregion

const helpers = {
  views: {
    analysis: {
      visualisation: {
        pptx: async (instance: ReturnType<typeof rc.registry.actions.getInstance>) => {
          // measures_data is a remote observable, so we need to await it whilst it is fetched from the server
          const measures_data = await legend.utils.awaitObservable(
            instance.remote.measures.data,
            instance.remote.measures.pending,
            instance.remote.measures.error,
          );

          const chart_config = instance.local.views.analysis.chart_config.get();
          const peerset = report_builder.remote.peerset.data.get();
          const current_measure_id = instance.local.views.analysis.selectors.measure.selectedMeasureId.get();
          const current_measure = instance.local.config.measure.measures
            .get()
            .find((measure) => measure.id === current_measure_id);

          // Get all unique keys except the category key (xAxisDataKey)
          const axisKey = chart_config.polar_axis.data_key;
          if (!axisKey) {
            throw new Error("X-axis data key not found");
          }
          const categories = measures_data.map((item) => item[axisKey] as string);

          // Transform data for each series with hex color conversion
          const seriesData = Object.keys(measures_data[0])
            .filter((key) => key !== axisKey)
            .map((key) => {
              const isBenchmark = key.startsWith("benchmark_");
              const companyKey = isBenchmark ? key.replace("benchmark_", "") : key;

              const output = {
                name: isBenchmark
                  ? `${peerset[companyKey]?.company_name || companyKey} (Benchmark)`
                  : peerset[key]?.company_name || key,
                labels: categories,
                values: measures_data.map((item) => Number(item[key]) || 0),
                color: chart_config.entities[key]?.color ? hslToHex(chart_config.entities[key].color) : "#000000",
                dashType: isBenchmark ? "dash" : "solid",
              };
              return output;
            });

          // create PPTX
          const pptx_slide: IRC_Exportable_PPTX_Chart = {
            type: "radar",
            data: seriesData.map((series) => ({
              name: series.name,
              labels: series.labels,
              values: series.values,
              dashType: series.dashType,
            })),
            options: {
              title: `${current_measure?.label}`,
              showTitle: true,
              titleFontSize: 12,
              x: 0.5,
              y: 0.9,
              w: "90%",
              h: "80%",
              chartColors: seriesData.map((series) => series.color),
              showLegend: true,
              legendPos: "b",
              chartArea: { border: { type: "solid", color: "888888", pt: 1 } },
              lineSize: 2,
              showValue: false,
              valAxisMinVal: current_measure?.domain?.[0],
              valAxisMaxVal: current_measure?.domain?.[1],
              catAxisTitle: "Time (Years)",
              showCatAxisTitle: true,
              valAxisTitle: current_measure?.label || "",
              showValAxisTitle: true,
              valAxisLabelFontSize: 10,
              valAxisLabelFontFace: "Arial",
              valAxisLabelColor: "363636",
            },
          };

          return pptx_slide;
        },
        csv: async (instance: ReturnType<typeof rc.registry.actions.getInstance>) => {
          // measures_data is a remote observable, so we need to await it whilst it is fetched from the server
          const measures_data = await legend.utils.awaitObservable(
            instance.remote.measures.data,
            instance.remote.measures.pending,
            instance.remote.measures.error,
          );

          const chart_config = instance.local.views.analysis.chart_config.get();
          const peerset = report_builder.remote.peerset.data.get();

          const current_measure_id = instance.local.views.analysis.selectors.measure.selectedMeasureId.get();
          const current_measure = instance.local.config.measure.measures
            .get()
            .find((measure) => measure.id === current_measure_id);

          const axisKey = chart_config.polar_axis.data_key;
          if (!axisKey) {
            throw new Error("X-axis data key not found");
          }

          // Convert company IDs to names in headers, handling benchmarks
          const originalHeaders = Object.keys(measures_data[0]).filter((key) => key !== axisKey);
          const convertedHeaders = originalHeaders.map((header) => {
            const isBenchmark = header.startsWith("benchmark_");
            if (isBenchmark) {
              return sanitizeForCSV(header.replace("benchmark_", "Benchmark: "));
            }
            return sanitizeForCSV(peerset[header]?.company_name || header);
          });

          // Create CSV header with measure and topic columns
          const headers = ["Measure", capitalize(axisKey), ...convertedHeaders];
          const csvContent = [headers.join(",")];

          // Create CSV rows with measure and topic values
          measures_data.forEach((item) => {
            const measureLabel = current_measure?.label || "";
            const rowData = [
              sanitizeForCSV(measureLabel),
              sanitizeForCSV(item[axisKey]?.toString() || ""),
              ...originalHeaders.map((header) => sanitizeForCSV(item[header]?.toString() || "")),
            ];
            csvContent.push(rowData.join(","));
          });

          const output = csvContent.join("\n");
          return output;
        },
      },
      evidence: {
        pptx: async (instance: ReturnType<typeof rc.registry.actions.getInstance>) => {
          // evidence is a remote observable, so we need to await it whilst it is fetched from the server
          const evidenceData = await legend.utils.awaitObservable(
            instance.remote.evidence.data,
            instance.remote.evidence.pending,
            instance.remote.evidence.error,
          );

          // Peerset is not a remote observable, so we can just grab it from the local state
          const peerset = report_builder.remote.peerset.data.get();

          const table: pptxgen.TableRow[] = [];

          table.push([
            { text: "Company" },
            { text: "Group" },
            { text: "Topic" },
            { text: "Sentiment" },
            { text: "Text" },
          ]);

          for (const [group_id, company_extracts] of Object.entries(evidenceData)) {
            for (const [topic_id, topic_extracts] of Object.entries(company_extracts)) {
              for (const extracts of Object.values(topic_extracts)) {
                for (const extract of extracts) {
                  const company = peerset[extract.company_id];
                  table.push([
                    { text: company?.company_name },
                    { text: group_id },
                    { text: extract.topic_name },
                    { text: extract.sentiment_type },
                    { text: extract.text },
                  ]);
                }
              }
            }
          }

          const output: IRC_Exportable_PPTX_Table = {
            title: `Evidence - What are the strengths and weaknesses of a Company's Culture?`,
            data: table,
            options: {
              x: 0.5,
              y: 0.9,
              w: 9.0,
              color: "363636",
              border: { type: "solid" },
              autoPage: true,
            },
          };

          return output;
        },
        csv: async (instance: ReturnType<typeof rc.registry.actions.getInstance>) => {
          // evidence is a remote observable, so we need to await it whilst it is fetched from the server
          const evidenceData = await legend.utils.awaitObservable(
            instance.remote.evidence.data,
            instance.remote.evidence.pending,
            instance.remote.evidence.error,
          );

          // Peerset is not a remote observable, so we can just grab it from the local state
          const peerset = report_builder.remote.peerset.data.get();

          // Create CSV headers
          const headers = ["Company", "Group", "Topic", "Sentiment", "Text"];
          const csvContent = [headers.join(",")];

          for (const [group_id, company_extracts] of Object.entries(evidenceData)) {
            for (const [topic_id, topic_extracts] of Object.entries(company_extracts)) {
              for (const extracts of Object.values(topic_extracts)) {
                for (const extract of extracts) {
                  const company = peerset[extract.company_id];
                  const rowData = [
                    sanitizeForCSV(company?.company_name),
                    sanitizeForCSV(group_id),
                    sanitizeForCSV(extract.topic_name),
                    sanitizeForCSV(extract.sentiment_type),
                    sanitizeForCSV(extract.text),
                  ];
                  csvContent.push(rowData.join(","));
                }
              }
            }
          }

          const csv = csvContent.join("\n");
          return csv;
        },
      },
    },
  },
};
