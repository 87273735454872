// Helpers

import { merge } from "lodash";
import {
  local_schema,
  ILocalState$,
  IRemoteState$,
  ICreateStateProps,
  IObservers,
  IActions,
  IHelpers,
  ITransformedCompany,
} from "./types";
import { api, IAPI, IContentExtract } from "@/core/shared/api";
import { transforms } from "@/core/shared/transforms";
import { z } from "zod";
import { legend } from "@/core/utils/legend-state/core";
import { rc } from "../../rc";
import { auth } from "@/core/state/auth";
import { metadata } from "@/core/state/metadata";
import { report_builder } from "@/features/report-builder/state";

// Config
const config: z.infer<typeof local_schema>["config"] = {
  company: {
    measure_id_to_measure_name_map: {
      NSS: "NSS_PER_COMPANY_PER_TOPIC",
      PREVALENCE: "PREVALENCE_PER_COMPANY_PER_TOPIC",
      RATING: "RATING_PER_COMPANY_PER_TOPIC",
      REVIEW_COUNT: "COUNT_PER_COMPANY_PER_TOPIC",
    },
    filters: {
      hide: false,
    },
  },
  measure: {
    hide: false,
    default: "NSS",
    measures: [
      {
        id: "NSS",
        label: "Employee Net Sentiment Score (NSS)",
        unit: "%",
        acronym: "NSS",
        showSign: true,
        domain: [-100, 100],
        measure_name: "nss_per_company",
        filter: {
          checked: true,
          disabled: false,
        },
      },
      {
        id: "PREVALENCE",
        label: "Topic Frequency ",
        unit: "%",
        acronym: "PREV",
        domain: [0, 100],
        measure_name: "prevalence_per_company",
        filter: {
          checked: true,
          disabled: false,
        },
      },
      {
        id: "RATING",
        label: "Employee Rating",
        domain: [0, 5],
        measure_name: "rating_per_company",
        filter: {
          checked: true,
          disabled: false,
        },
      },
      {
        id: "REVIEW_COUNT",
        label: "Employee Review Count",
        domain: undefined,
        compactYAxisValues: true,
        measure_name: "count_per_company",
        filter: {
          checked: true,
          disabled: false,
        },
      },
    ],
  },
  sort: {
    hide: false,
    default: "DESC",
    directions: [
      {
        id: "ASC",
        label: "Worst to Top Performer",
        filter: {
          enabled: true,
          disabled: false,
          applied: false,
        },
      },
      {
        id: "DESC",
        label: "Top to Worst Performer",
        filter: {
          enabled: true,
          disabled: false,
          applied: true,
        },
      },
    ],
  },
};

export function createState(
  instance_id: string,
  props: ICreateStateProps,
): { local: ILocalState$; remote: IRemoteState$ } {
  const local = helpers.validateAndMergeState(
    {
      label: "Employee Voice: Company Personality",
      ui_merged: false,
      config,
      views: {
        currentView: "analysis",
        analysis: {
          selectors: {
            measure: {
              selectedMeasureId: "NSS",
            },
            sort_direction: {
              selectedSortDirectionId: "DESC",
            },
          },
          filters: {
            company: legend.features.filter.createObservableState(),
            timeframe: legend.features.filter.createObservableState(),
            applied_filters: {
              company_id: [],
              timeframe: [],
            },
          },
        },
      },
    },
    props.local,
  );

  const remote: IRemoteState$ = {
    companies: {
      pending: false,
      error: false,
      data: legend.sync.synced({
        initial: [],
        get: async () => {
          const instance = rc.registry.actions.getInstance(instance_id);
          try {
            instance.remote.companies.pending.set(true);

            const measure_id = instance.local.views.analysis.selectors.measure.selectedMeasureId.get();
            const measure = instance.local.config.measure.measures.get().find((m) => m.id === measure_id);

            const company_ids = instance.local.views.analysis.filters.applied_filters.company_id.get();

            const sort_direction_id =
              instance.local.views.analysis.selectors.sort_direction.selectedSortDirectionId.get();
            const sort_direction = instance.local.config.sort.directions.get().find((d) => d.id === sort_direction_id);

            const timeframe = instance.local.views.analysis.filters.applied_filters.timeframe.get();

            if (!company_ids) {
              throw new Error("No company ids provided");
            }

            if (!measure) {
              throw new Error("No measure provided");
            }

            const params: Parameters<IAPI["deltabase_data_api"]["companies"]["get"]>["0"] = {
              company_id: company_ids,
              sort_field: measure?.measure_name ?? undefined,
              sort_direction: sort_direction?.id ?? undefined,
              use_new_sectors: "true",
              year: timeframe,
            };

            // Guard against no company selected - Data API will return an error 400 otherwise
            if (params.company_id?.length === 0) {
              return [];
            }

            const locationMetadata = metadata.remote$.data.cached_transformed["country"].get(true);
            if (!locationMetadata) return;

            const response = await api.deltabase_data_api.companies.get(params);

            const transformedData: Array<ITransformedCompany> = response.data.map((company) => {
              const out = helpers.transformCompany(company, locationMetadata, measure);
              return out;
            });
            legend.state.batch(() => {
              instance.remote.companies.pending.set(false);
              instance.remote.companies.error.set(false);
            });
            return transformedData;
          } catch (error) {
            legend.state.batch(() => {
              instance.remote.companies.pending.set(false);
              instance.remote.companies.error.set(true);
            });
          }
        },
        waitFor: () => {
          const token = auth.state$.token.get();
          return !!token;
        },
      }),
    },
    evidence: {
      pending: false,
      error: false,
      data: legend.sync.synced({
        initial: {},
        get: async () => {
          const instance = rc.registry.actions.getInstance(instance_id);
          try {
            instance.remote.evidence.pending.set(true);
            const topic_metadata = metadata.remote$.data.cached_transformed["topic"].get();

            const params: Parameters<IAPI["deltabase_data_api"]["content_extracts"]["get"]>["0"] = {
              company_id: instance.local.views.analysis.filters.applied_filters.company_id.get(),
              year: instance.local.views.analysis.filters.applied_filters.timeframe.get(),
              sentiment_type: [],
            };

            if (params.company_id?.length === 0) {
              return {};
            }

            const response = await api.deltabase_data_api.content_extracts.get(params);

            const evidenceData: Record<string, Record<string, Record<string, Array<IContentExtract>>>> = {};

            response.data.forEach((extract) => {
              const { topic_name, company_id, text, sentiment_type } = extract;
              const topic = topic_metadata.find((t) => t.meta_data_value === topic_name);

              const group = topic?.meta_data_parent_value ?? topic_name;
              if (!group) return;

              // Initialize nested structure
              if (!evidenceData[group]) evidenceData[group] = {};
              if (!evidenceData[group][topic_name]) evidenceData[group][topic_name] = {};
              if (!evidenceData[group][topic_name][company_id]) evidenceData[group][topic_name][company_id] = [];

              const cleanedExtract = {
                ...extract,
                text: text.replace(/['"]/g, ""),
              };

              const currentExtracts = evidenceData[group][topic_name][company_id];
              const hasBothSentiments =
                (params?.sentiment_type?.includes("Positive") && params?.sentiment_type?.includes("Negative")) ||
                params?.sentiment_type?.length === 0;

              if (hasBothSentiments) {
                // If we want both sentiments, ensure we get one positive and one negative
                if (sentiment_type === "Positive" && !currentExtracts.some((e) => e.sentiment_type === "Positive")) {
                  currentExtracts.unshift(cleanedExtract); // Add positive first
                } else if (
                  sentiment_type === "Negative" &&
                  !currentExtracts.some((e) => e.sentiment_type === "Negative")
                ) {
                  currentExtracts.push(cleanedExtract); // Add negative second
                }
              } else if (currentExtracts.length < 2) {
                // If not filtering by both sentiments, just add up to 2 extracts
                currentExtracts.push(cleanedExtract);
              }
            });

            // Sort topics alphabetically within each group
            for (const group in evidenceData) {
              const sortedTopics = Object.keys(evidenceData[group]).sort();
              const sortedGroupData: Record<string, Record<string, Array<IContentExtract>>> = {};

              sortedTopics.forEach((topicName) => {
                sortedGroupData[topicName] = evidenceData[group][topicName];
              });

              evidenceData[group] = sortedGroupData;
            }

            legend.state.batch(() => {
              instance.remote.evidence.pending.set(false);
              instance.remote.evidence.error.set(false);
            });
            return evidenceData;
          } catch (error) {
            legend.state.batch(() => {
              instance.remote.evidence.pending.set(false);
              instance.remote.evidence.error.set(true);
            });
          }
        },
        waitFor: () => {
          const token = auth.state$.token.get();
          return !!token;
        },
      }),
    },
  };

  return { local, remote };
}

export const actions: IActions = {
  views: {
    analysis: {
      selectors: {
        measure: {
          set: (instance_id: string, measure_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const measure = instance.local.config.measure.measures.get().find((m) => m.id === measure_id);
            if (!measure) return;
            instance.local.views.analysis.selectors.measure.selectedMeasureId.set(measure_id);
          },
        },
      },
      filters: {
        company: {
          applyAll: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const applied: string[] = [];
            const filters = instance.local.views.analysis.filters.company.data.get();
            Object.values(filters).forEach((filter) => {
              if (filter.checked) {
                applied.push(filter.id);
              }
            });
            instance.local.views.analysis.filters.applied_filters.company_id.set(applied);
          },
          toggle: (instance_id: string, filter_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggle({
              state: instance.local.views.analysis.filters.company,
              params: { id: filter_id },
            });
          },
          toggleGroup: (instance_id: string, filter_group: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggleGroup({
              state: instance.local.views.analysis.filters.company,
              params: { group: filter_group },
            });
          },
          getFiltersByGroup: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const out = legend.features.filter.getFiltersByGroup({
              state: instance.local.views.analysis.filters.company,
            });
            return out;
          },
        },
        timeframe: {
          applyAll: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const applied: string[] = [];
            const filters = instance.local.views.analysis.filters.timeframe.data.get();
            Object.values(filters).forEach((filter) => {
              if (filter.checked) {
                applied.push(filter.id);
              }
            });
            instance.local.views.analysis.filters.applied_filters.timeframe.set(applied);
          },
          toggle: (instance_id: string, filter_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggle({
              state: instance.local.views.analysis.filters.timeframe,
              params: { id: filter_id },
            });
          },
          toggleGroup: (instance_id: string, filter_group: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggleGroup({
              state: instance.local.views.analysis.filters.timeframe,
              params: { group: filter_group },
            });
          },
          toggleByRange: (instance_id, range) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const filters_obs = instance.local.views.analysis.filters.timeframe.data;
            const filters_arr = Object.values(filters_obs.get());
            const [startYear, endYear] = range.split("-");

            // For single year selection
            if (!endYear) {
              const targetFilter = filters_arr.find((filter) => filter.id === startYear);
              const newCheckedState = !targetFilter?.checked;

              filters_arr.forEach((filter) => {
                const checked = filter.id === startYear ? newCheckedState : false;
                filters_obs[filter.id].checked.set(checked);
              });
              return;
            }

            // For year range selection
            // First check if all filters in range are already checked
            const filtersInRange = filters_arr.filter((filter) => {
              const year = parseInt(filter.id);
              return year >= parseInt(startYear) && year <= parseInt(endYear);
            });
            const allChecked = filtersInRange.every((filter) => filter.checked);

            // Toggle based on current state
            filters_arr.forEach((filter) => {
              const year = parseInt(filter.id);
              const inRange = year >= parseInt(startYear) && year <= parseInt(endYear);
              // If all are checked, uncheck them; if not all checked, check them
              const checked = inRange ? !allChecked : false;
              filters_obs[filter.id].checked.set(checked);
            });
          },
          getFiltersByGroup: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const out = legend.features.filter.getFiltersByGroup({
              state: instance.local.views.analysis.filters.timeframe,
            });
            return out;
          },
        },
      },
    },
  },
};

export const observers: IObservers = {
  view: {
    analysis: {
      filters: {
        company: {
          init: (instance_id: string) => {
            if (!instance_id) return;
            legend.state.observe<{ peerset_key: string }>((e) => {
              const instance = rc.registry.actions.getInstance(instance_id);
              const peerset = report_builder.remote.peerset.data.get();
              const current_peerset_key = JSON.stringify(Object.keys(peerset));

              if (!peerset || current_peerset_key.length === 0 || current_peerset_key === e.previous?.peerset_key) {
                return;
              }

              const companies = Object.values(peerset);

              // populate filter state
              legend.state.batch(() => {
                legend.features.filter.batchAddFilter({
                  state: instance.local.views.analysis.filters.company,
                  params: {
                    data: companies.map((company, idx) => ({
                      id: company.company_id,
                      label: company.company_name,
                      checked: true,
                      disabled: false,
                      group: "",
                    })),
                  },
                });
                actions.views.analysis.filters.company.applyAll(instance_id);
              });

              const peerset_key = JSON.stringify(Object.keys(peerset));

              return { peerset_key };
            });
          },
        },
        timeframe: {
          init: (instance_id: string) => {
            if (!instance_id) return;
            legend.state.observe<{ initialised: boolean }>((e) => {
              if (e.previous?.initialised) return;
              const instance = rc.registry.actions.getInstance(instance_id);
              const timeframe_metadata = metadata.remote$.data.get().cached_transformed["year"];
              const company_search_timeframe = report_builder.local.search.time_periods.get();

              if (!timeframe_metadata || timeframe_metadata.length === 0 || company_search_timeframe.length === 0) {
                return { initialised: false };
              }

              // populate filter state
              legend.state.batch(() => {
                legend.features.filter.batchAddFilter({
                  state: instance.local.views.analysis.filters.timeframe,
                  params: {
                    data: timeframe_metadata.map((timeframe) => {
                      const out = {
                        id: timeframe.meta_data_value,
                        label: timeframe.meta_data_value.toLowerCase(),
                        checked: company_search_timeframe?.includes(timeframe.meta_data_value) ?? false,
                        disabled: false,
                        group: "",
                      };
                      return out;
                    }),
                  },
                });
                actions.views.analysis.filters.timeframe.applyAll(instance_id);
              });

              return { initialised: true };
            });
          },
        },
      },
    },
  },
};

const helpers: IHelpers = {
  validateAndMergeState: (state: ILocalState$, new_state?: object) => {
    if (new_state !== undefined) {
      const isValid = local_schema.safeParse(new_state);
      if (!isValid.success) {
        throw new Error(
          `Invalid props provided to createState$. Validation errors: ${JSON.stringify(isValid.error.format(), null, 2)}`,
        );
      }
      state = merge(state, new_state);
      return state;
    }
    return state;
  },
  transformCompany: (company, metadata, measure) => {
    const location = metadata?.find((loc) => loc.meta_data_value === company.company_headquarters);

    let measure_score: ITransformedCompany["measure_score"] = null;

    if (measure.measure_name === "nss_per_company") {
      measure_score = transforms.nss.toPercentageString(company.nss_per_company);
    }

    if (measure.measure_name === "prevalence_per_company") {
      measure_score = transforms.prevalence.toPercentageString(company.prevalence_per_company);
    }

    if (measure.measure_name === "count_per_company") {
      measure_score = transforms.count.toCompactNumber(company.count_per_company);
    }

    if (measure.measure_name === "rating_per_company") {
      measure_score = transforms.rating.toString(company.rating_per_company);
    }

    return {
      ...company,
      measure_score,
      nss_per_company: {
        percentage: transforms.nss.toPercentage(company.nss_per_company),
        strength: transforms.nss.toStrength(company.nss_per_company),
        sentiment: transforms.nss.toSentiment(company.nss_per_company),
        percentage_string: transforms.nss.toPercentageString(company.nss_per_company),
      },
      prevalence_per_company: {
        percentage: transforms.prevalence.toPercentage(company.prevalence_per_company),
        percentage_string: transforms.prevalence.toPercentageString(company.prevalence_per_company),
      },
      count_per_company: {
        count: transforms.count.toNumber(company.count_per_company),
        compact_number: transforms.count.toCompactNumber(company.count_per_company),
        strength: transforms.count.toStrength(company.signal_strength),
      },
      rating_per_company: {
        rating: transforms.rating.toNumber(company.rating_per_company),
      },
      sectors: {
        primary: company.sectors.filter((sector) => sector.sector_is_primary),
        secondary: company.sectors.filter((sector) => !sector.sector_is_primary),
      },
      company_headcount: {
        compact_number: transforms.headcount.toCompactNumber(company.company_headcount?.toString()),
      },
      company_headquarters: {
        id: location?.meta_data_value,
        label: location?.meta_data_description ?? location?.meta_data_value,
      },
    };
  },
};
