import { auth } from "../../state";
import axios from "axios";
import { env } from "@/env";
import { IAPI, IDataApiResponse, IMetadata, ICompany, IMeasure, IContentExtract } from "./types";

const clients = {
  deltabase_data_api: axios.create({
    baseURL: env.VITE_DATA_API_URL,
    paramsSerializer: {
      indexes: null,
    },
    timeout: 30_000,
  }),
};

export const api: IAPI = {
  deltabase_data_api: {
    metadata: {
      get: async () => {
        const token = auth.state$.token.get();
        const response = await clients.deltabase_data_api.get<IDataApiResponse<IMetadata[]>>("/meta_data", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status !== 200) {
          throw new Error(`Failed to fetch metadata: ${response.data.code} status ${response.data.status}`);
        }
        return response.data;
      },
    },
    companies: {
      get: async (params) => {
        const token = auth.state$.token.get();
        const response = await clients.deltabase_data_api.get<IDataApiResponse<ICompany[]>>("/companies", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        });
        if (response.data.status !== 200) {
          throw new Error(`Failed to fetch metadata: ${response.data.code} status ${response.data.status}`);
        }
        return response.data;
      },
    },
    measures: {
      get: async (params) => {
        const token = auth.state$.token.get();
        const response = await clients.deltabase_data_api.get<IDataApiResponse<IMeasure[]>>("/measures", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        });
        if (response.data.status !== 200) {
          throw new Error(`Failed to fetch metadata: ${response.data.code} status ${response.data.status}`);
        }
        return response.data;
      },
    },
    content_extracts: {
      get: async (params) => {
        const token = auth.state$.token.get();
        const response = await clients.deltabase_data_api.get<IDataApiResponse<IContentExtract[]>>(
          "/content_extracts",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params,
          },
        );
        if (response.data.status !== 200) {
          throw new Error(`Failed to fetch content extracts: ${response.data.code} status ${response.data.status}`);
        }
        return response.data;
      },
    },
  },
};
