import { Button } from "@/core/shadcn/ui/button";
import { state } from "../../state/state";
import { ChevronRight } from "lucide-react";
import { legend } from "@/core/utils/legend-state/core";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { testIds } from "../../test-ids";

import { notify } from "@/core/notifications";

export const BenchmarkButton = () => {
  const navigate = useNavigate();

  function handleClick() {
    const timePeriods = state.remote$.companies.params.years.get();
    const peersetIds = Object.keys(state.local$.peerset.data.get());
    if (peersetIds.length === 0) {
      notify.defaultError("Please add at least one company to your peer set");
      return;
    }
    navigate({
      from: "/",
      to: "/report-builder",
      replace: false,
      search: {
        peersetIds: Object.keys(state.local$.peerset.data.get()),
        timePeriods,
      },
    });
  }
  return (
    <Button
      testId={testIds.components.benchmarkButton["data-testid"]}
      className="hover:bg-[hsl(224.3,76.3%,42%)] bg-[hsl(224.3,76.3%,48%)] text-white font-normal"
      variant="default"
      onClick={handleClick}
    >
      Build My Report <ChevronRight />
    </Button>
  );
};
