import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/core/shadcn/ui/collapsible";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/core/shadcn/ui/sidebar";

import { SidebarGroup, SidebarGroupLabel } from "@/core/shadcn/ui/sidebar";
import { ChevronRight } from "lucide-react";
import { state$ } from "../../state/state";
import { legend } from "@/core/utils/legend-state/core";
import { useRouter } from "@tanstack/react-router";
import { cn } from "@/core/shadcn/utils";
import { notify } from "@/core/notifications";

export const MainNavItems = legend.react.observer(() => {
  const router = useRouter();
  const data = state$.local$.getMainAsArray();
  return (
    <SidebarGroup>
      <SidebarGroupLabel>Main Areas</SidebarGroupLabel>
      <SidebarMenu>
        {data.map((item) => (
          <Collapsible key={item.title} asChild defaultOpen={item.isActive} className="group/collapsible">
            <SidebarMenuItem>
              {item.items && item.items.length > 0 ? (
                <>
                  <CollapsibleTrigger asChild>
                    <SidebarMenuButton tooltip={item.title}>
                      {item.icon && <item.icon />}
                      <span>{item.title}</span>
                      <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90 " />
                    </SidebarMenuButton>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <SidebarMenuSub>
                      {item.items?.map((subItem) => (
                        <SidebarMenuSubItem key={subItem.title}>
                          <SidebarMenuSubButton
                            asChild
                            className={cn(subItem.disabled && "opacity-50 cursor-not-allowed")}
                            onClick={() => {
                              if (subItem.disabled) {
                                notify.defaultMessage(`${subItem.title} is currently under development`);
                                return;
                              }
                              if (subItem.isExternal) {
                                window.open(subItem.url, "_blank", "noopener,noreferrer");
                                return;
                              }
                              // Add Tanstack Router navigation
                              router.navigate({
                                to: subItem.url,
                              });
                            }}
                          >
                            <span>{subItem.title}</span>
                          </SidebarMenuSubButton>
                        </SidebarMenuSubItem>
                      ))}
                    </SidebarMenuSub>
                  </CollapsibleContent>
                </>
              ) : (
                <SidebarMenuButton
                  tooltip={item.title}
                  className={cn(item.disabled && "opacity-50 cursor-not-allowed")}
                  onClick={() => {
                    if (item.disabled) {
                      notify.defaultMessage(`${item.title} is currently under development`);
                      return;
                    }
                    if (item.isExternal) {
                      window.open(item.url, "_blank", "noopener,noreferrer");
                      return;
                    }
                    // Add Tanstack Router navigation
                    router.navigate({
                      to: item.url,
                    });
                  }}
                >
                  {item.icon && <item.icon />}
                  <span className="font-sans font-medium">{item.title}</span>
                </SidebarMenuButton>
              )}
            </SidebarMenuItem>
          </Collapsible>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
});
