import { legend } from "@/core/utils/legend-state/core";
import JSZip from "jszip";
import pptxgen from "pptxgenjs";
import { IActions, IHelpers, ILocal$, IRemote$, IPeersetCompany } from "./types";
import { sleep, uid } from "radash";
import { employee_voice_single_company_correlation } from "../rcs/culture/employee-voice/correlation";
import { employee_voice_single_company_multi_topic_trend } from "../rcs/culture/employee-voice/mutli-topic-trend";
import { employee_voice_company_personality } from "../rcs/culture/employee-voice/company-personality";
import { employee_voice_multi_company_single_topic_trend } from "../rcs/culture/employee-voice/single-topic-trend";
import { employee_voice_company_ranking } from "../rcs/culture/employee-voice/company-ranking";
import { employee_voice_extracts } from "../rcs/culture/employee-voice/extracts";
import { rc } from "../rcs/culture/employee-voice/single-topic-trend/rc";
import { notify } from "@/core/notifications";
import { clone } from "lodash";
import { api } from "@/core/shared/api";
import { metadata } from "@/core/state/metadata";
import { transforms } from "@/core/shared/transforms";

// 1000
const SLEEP_TIME = 1000;

export const local$ = legend.state.observable<ILocal$>({
  search: {
    report_id: "",
    peerset_ids: [],
    time_periods: [],
  },
  active_rc_registries: legend.sync.synced({
    initial: {},
    persist: {
      name: "report-builder-active-rc-registries",
      plugin: legend.local_storage.ObservablePersistLocalStorage,
    },
  }),
  export: {
    pending: false,
  },
});

export const remote$ = legend.state.observable<IRemote$>({
  peerset: {
    pending: false,
    error: false,
    data: legend.sync.synced({
      initial: {},
      get: async () => {
        try {
          remote$.peerset.pending.set(true);
          const params: Parameters<typeof api.deltabase_data_api.companies.get>[0] = {
            company_id: local$.search.peerset_ids.get(),
            year: local$.search.time_periods.get(),
          };

          const locationMetadata = metadata.remote$.data.cached_transformed["country"].get(true);
          if (!locationMetadata) return;

          const response = await api.deltabase_data_api.companies.get(params);

          const transformedData = response.data.reduce(
            (acc, company) => {
              const out = helpers.transformCompany(company, locationMetadata);
              acc[company.company_id] = out;
              return acc;
            },
            {} as Record<string, IPeersetCompany>,
          );

          return transformedData;
        } catch (error) {
          // TODO: Handle error
          legend.state.batch(() => {
            remote$.peerset.pending.set(false);
            remote$.peerset.error.set(true);
          });
        } finally {
          remote$.peerset.pending.set(false);
        }
      },
    }),
  },
  report: {
    progress: 0,
    pending: false,
    error: false,
    data: legend.sync.synced({
      initial: {
        title: "",
        rcs: [],
      },
      get: async () => {
        try {
          // Make function reactive to report_id
          const report_id = local$.search.report_id.get();
          await sleep(SLEEP_TIME);
          legend.state.batch(() => {
            remote$.report.pending.set(true);
            remote$.report.progress.set(40);
          });

          // DUMMY FETCH
          const report = await helpers.fetchDummyReport();
          report.rcs.forEach((remote_rc) => {
            const rc = helpers.resolveRemoteRCs(remote_rc);
            rc?.registry.actions.createInstance(remote_rc.instance_id, {
              local: remote_rc.local$,
            });
          });

          legend.state.batch(() => {
            remote$.report.progress.set(80);
            remote$.report.pending.set(false);
          });

          return report;
        } catch (error) {
          legend.state.batch(() => {
            remote$.report.progress.set(0);
            remote$.report.error.set(true);
          });
        } finally {
          remote$.report.pending.set(false);
        }
      },
    }),
  },
});

export const actions: IActions = {
  export: async () => {
    try {
      local$.export.pending.set(true);

      const remote_rcs = remote$.report.data.rcs.get();
      const zip = new JSZip();

      const rcs_folder = zip?.folder("components");

      // Collect all RC export data in parallel
      // Each RC can have multiple exportables (charts, CSVs, etc.)
      const rc_promises = remote_rcs.map((remote_rc) => {
        const rc = helpers.resolveRemoteRCs(remote_rc);
        if (!rc) {
          throw new Error(`RC not found for remote RC with id ${remote_rc.instance_id}`);
        }
        if (!rc.registry.actions.exportInstance) {
          throw new Error(`RC with id ${remote_rc.instance_id} does not have exportInstance method`);
        }
        const promise = rc.registry.actions.exportInstance(remote_rc.instance_id);
        return promise;
      });
      const rc_exportables = await Promise.all(rc_promises);

      // Track PowerPoint write promises to handle them in parallel later
      const pptxWritePromises: Promise<void>[] = [];

      for (const rc_exportable of rc_exportables) {
        if (!rc_exportable) continue;

        // Create folder for each RC
        const rc_folder = rcs_folder?.folder(rc_exportable.folder_path);
        if (!rc_folder) continue;

        const merge_views_to_master_pptx = rc_exportable.master.pptx?.merge_views_to_master_pptx;

        // Create master PowerPoint for each RC
        let component_pptx: pptxgen | undefined;
        if (merge_views_to_master_pptx) {
          component_pptx = new pptxgen();
        }

        // Create exports for each component view
        for (const view of rc_exportable.views) {
          // Create folder for each view
          // const view_folder = rc_folder?.folder(view.view_name);
          // if (!view_folder) continue;

          const view_pptx_config = view.formats.pptx;
          const view_csv_config = view.formats.csv;

          // Create PowerPoint for each view
          if (view_pptx_config) {
            if (component_pptx) {
              const component_title_slide = component_pptx.addSlide();
              component_title_slide.addText(view_pptx_config.powerpoint_name, {
                x: 1,
                y: 2.185039,
                w: "80%", // Set width to 80% of slide
                align: "center", // Center align the text
                valign: "middle", // Vertically center the text
                h: "20%", // Set height to 20% of slide
                autoFit: true,
                color: "363636",
                fontSize: 24,
              });
            }

            // Create slide for view
            const view_pptx = new pptxgen();

            // Add slides to component and view PowerPoints
            for (const slide_data of view_pptx_config.slide_data) {
              if (slide_data.chart !== undefined) {
                const component_slide = component_pptx?.addSlide();
                const view_slide = view_pptx.addSlide();

                // IMPORTANT: Deep clone chart data to prevent shared references between PowerPoints
                // Without this, the chart data can become corrupted when writing multiple files
                if (component_slide) {
                  if (slide_data.title) {
                    component_slide.addText(slide_data.title, {
                      x: 0.5,
                      y: 0.5,
                      w: "100%",
                      color: "black",
                      underline: { style: "sng" },
                    });
                  }
                  const component_chart_data = clone(slide_data.chart.data);
                  const component_chart_options = clone(slide_data.chart.options);
                  component_slide.addChart(slide_data.chart.type, component_chart_data, component_chart_options);
                }

                if (slide_data.title) {
                  view_slide.addText(slide_data.title, {
                    x: 0.5,
                    y: 0.5,
                    w: "100%",
                    color: "black",
                    underline: { style: "sng" },
                  });
                }
                const view_chart_data = clone(slide_data.chart.data);
                const view_chart_options = clone(slide_data.chart.options);
                view_slide.addChart(slide_data.chart.type, view_chart_data, view_chart_options);
              }

              // Add table to component and view PowerPoints
              if (slide_data.table) {
                const component_slide = component_pptx?.addSlide();
                const view_slide = view_pptx.addSlide();

                if (slide_data.title) {
                  view_slide.addText(slide_data.title, {
                    x: 0.5,
                    y: 0.5,
                    w: "100%",
                    color: "black",
                    underline: { style: "sng" },
                  });
                }

                if (component_slide) {
                  // Add title to component slide
                  component_slide.addText(slide_data.table.title, {
                    x: 0.5,
                    y: 0.5,
                    w: "100%",
                    color: "black",
                    underline: { style: "sng" },
                  });

                  // Add table to component slide
                  const component_table_data = clone(slide_data.table.data);
                  const component_table_options = clone(slide_data.table.options);
                  component_slide.addTable(component_table_data, component_table_options);
                }

                // Add title to view slide
                view_slide.addText(slide_data.table.title, {
                  x: 0.5,
                  y: 0.5,
                  w: "100%",
                  color: "black",
                  underline: { style: "sng" },
                });

                // Add table to view slide
                const view_table_data = clone(slide_data.table.data);
                const view_table_options = clone(slide_data.table.options);
                view_slide.addTable(view_table_data, view_table_options);
              }
            }

            pptxWritePromises.push(
              view_pptx.write({ outputType: "blob" }).then((blob) => {
                rc_folder.file(`views/${view.view_name}/${view_pptx_config.powerpoint_name}.pptx`, blob);
                return;
              }),
            );
          }

          // Create CSV for each view
          if (view_csv_config) {
            for (const csv_data of view_csv_config.data) {
              rc_folder.file(`views/${view.view_name}/csv/${csv_data.name}.csv`, csv_data.content);
            }
          }
        }

        if (component_pptx) {
          pptxWritePromises.push(
            component_pptx.write({ outputType: "blob" }).then((blob) => {
              rc_folder.file(`${rc_exportable.master.pptx?.powerpoint_name}.pptx`, blob);
              return;
            }),
          );
        }
      }

      // Wait for all component PowerPoints to be generated
      await Promise.all(pptxWritePromises);

      // Create and trigger download of the final zip file
      const zipContent = await zip.generateAsync({ type: "blob" });
      const url = URL.createObjectURL(zipContent);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `${remote$.report.data.title.get()}-report.zip`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      notify.defaultSuccess("Report exported successfully");
    } catch (error) {
      console.error(error);
    } finally {
      local$.export.pending.set(false);
    }
  },
};

const helpers: IHelpers = {
  resolveRemoteRCs: (remote_rc) => {
    switch (remote_rc.rc_id) {
      case employee_voice_single_company_correlation.rc.rc_id: {
        return employee_voice_single_company_correlation.rc;
      }
      case employee_voice_single_company_multi_topic_trend.rc.rc_id: {
        return employee_voice_single_company_multi_topic_trend.rc;
      }
      case employee_voice_company_personality.rc.rc_id: {
        return employee_voice_company_personality.rc;
      }
      case employee_voice_multi_company_single_topic_trend.rc.rc_id: {
        return employee_voice_multi_company_single_topic_trend.rc;
      }
      case employee_voice_company_ranking.rc.rc_id: {
        return employee_voice_company_ranking.rc;
      }
      case employee_voice_extracts.rc.rc_id: {
        return employee_voice_extracts.rc;
      }
      default: {
        return null;
      }
    }
  },
  fetchDummyReport: async () => {
    await sleep(SLEEP_TIME);
    const rcs = [
      {
        instance_id: "QWLKDNO3",
        rc_id: employee_voice_company_personality.rc.rc_id,
        local$: undefined,
        order: 1,
      },
      {
        instance_id: "QWLKDNO5",
        rc_id: employee_voice_company_ranking.rc.rc_id,
        local$: undefined,
        order: 1,
      },
      {
        instance_id: "QWLKDNO6",
        rc_id: employee_voice_extracts.rc.rc_id,
        local$: undefined,
        order: 1,
      },
      {
        instance_id: "QWLKDNO4",
        rc_id: employee_voice_multi_company_single_topic_trend.rc.rc_id,
        local$: undefined,
        order: 1,
      },
      {
        instance_id: "QWLKDNO2",
        rc_id: employee_voice_single_company_correlation.rc.rc_id,
        local$: undefined,
        order: 1,
      },
      {
        instance_id: "QWLKDNO1",
        rc_id: employee_voice_single_company_multi_topic_trend.rc.rc_id,
        local$: undefined,
        order: 1,
      },
      // {
      //   instance_id: "cefx_X4u",
      //   rc_id: employee_voice_single_company_correlation.rc.rc_id,
      //   local$: {
      //     label: "Measure Correlation",
      //     ui_merged: false,
      //     config: {
      //       company: {
      //         measure_filter_to_measure_name_map: {
      //           NSS: "NSS_PER_COMPANY_PER_TOPIC",
      //           PREVALENCE: "PREVALENCE_PER_COMPANY_PER_TOPIC",
      //           RATING: "RATING_PER_COMPANY_PER_TOPIC",
      //           REVIEW_COUNT: "COUNT_PER_COMPANY_PER_TOPIC",
      //         },
      //         filters: {
      //           hide: false,
      //         },
      //       },
      //       measure: {
      //         hide: false,
      //         default: "NSS",
      //         measures: [
      //           {
      //             id: "NSS",
      //             label: "Net Promoter Score",
      //             unit: "%",
      //             acronym: "NSS",
      //             showSign: true,
      //             domain: [-100, 100],
      //             filter: {
      //               applied: true,
      //               disabled: false,
      //             },
      //           },
      //           {
      //             id: "PREVALENCE",
      //             label: "Prevalence",
      //             unit: "%",
      //             acronym: "PREV",
      //             domain: [0, 100],
      //             filter: {
      //               applied: true,
      //               disabled: false,
      //             },
      //           },
      //           {
      //             id: "RATING",
      //             label: "Rating",
      //             domain: [0, 5],
      //             filter: {
      //               applied: true,
      //               disabled: false,
      //             },
      //           },
      //           {
      //             id: "REVIEW_COUNT",
      //             label: "Review count",
      //             compactYAxisValues: true,
      //             filter: {
      //               applied: true,
      //               disabled: false,
      //             },
      //           },
      //         ],
      //       },
      //     },
      //     views: {
      //       currentView: "analysis",
      //       analysis: {
      //         selectors: {
      //           company: {
      //             selectedCompanyId: "",
      //           },
      //           x_measure: {
      //             selectedMeasureId: "PREVALENCE",
      //           },
      //           y_measure: {
      //             selectedMeasureId: "NSS",
      //           },
      //         },
      //         filters: {
      //           topic: {
      //             stats: {
      //               totalApplied: 0,
      //               totalChecked: 0,
      //               totalDisabled: 0,
      //             },
      //             data: {},
      //           },
      //           timeframe: {
      //             stats: {
      //               totalApplied: 0,
      //               totalChecked: 0,
      //               totalDisabled: 0,
      //             },
      //             data: {},
      //           },
      //           applied_filters: {
      //             company_id: [],
      //             topic_name: [],
      //             year: [],
      //           },
      //         },
      //       },
      //     },
      //   },
      //   order: 1,
      // },
    ];
    return {
      title: "My Culture Report",
      rcs,
    };
  },
  transformCompany: (company, metadata) => {
    const location = metadata?.find((loc) => loc.meta_data_value === company.company_headquarters);
    return {
      ...company,
      nss_per_company: {
        percentage: transforms.nss.toPercentage(company.nss_per_company),
        strength: transforms.nss.toStrength(company.nss_per_company),
        sentiment: transforms.nss.toSentiment(company.nss_per_company),
        percentage_string: transforms.nss.toPercentageString(company.nss_per_company),
      },
      prevalence_per_company: {
        percentage: transforms.prevalence.toPercentage(company.prevalence_per_company),
        percentage_string: transforms.prevalence.toPercentageString(company.prevalence_per_company),
      },
      count_per_company: {
        count: transforms.count.toNumber(company.count_per_company),
        compact_number: transforms.count.toCompactNumber(company.count_per_company),
        strength: transforms.count.toStrength(company.signal_strength),
      },
      rating_per_company: {
        rating: transforms.rating.toNumber(company.rating_per_company),
      },
      sectors: {
        primary: company.sectors.filter((sector) => sector.sector_is_primary),
        secondary: company.sectors.filter((sector) => !sector.sector_is_primary),
      },
      company_headcount: {
        compact_number: transforms.headcount.toCompactNumber(company.company_headcount?.toString()),
      },
      company_headquarters: {
        id: location?.meta_data_value,
        label: location?.meta_data_description ?? location?.meta_data_value,
      },
    };
  },
};
